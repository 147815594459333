import { Component, Show, Accessor, ErrorBoundary } from "solid-js";
import { createAsync, useNavigate } from "@solidjs/router";
import { type SubscriptionData } from "~/services/roma-api/inventory-subscriptions";
import { StockSubscription } from "./StockSubscription";
import { BaseLoader } from "../utility";
import { Modal } from "../utility";
import { getProductPricing, useProduct } from "~/services/products";
import { checkError } from "~/services/roma-api/errors";
import { useErrorContext } from "~/utils/contexts";

export const ManageSubscription: Component<{
  open: Accessor<boolean>;
  onClose: () => void;
  sku: string;
  activeSubscription: SubscriptionData;
}> = (props) => {
  const { addError } = useErrorContext();
  const navigate = useNavigate();
  const defaultErrorConfig = {
    showDetails: true,
    severity: "critical" as const,
    title: "Product Error",
    message:
      "An error occurred while retrieving product information. Please try again. If the error persits, kindly contact customer support. We apologize for the inconvenience.",
    actions: [
      {
        label: "Contact Support",
        action: async () => navigate("/support"),
      },
    ],
  };

  // TODO
  const inventory = createAsync(async () => {
    try {
      return await getProductPricing(props.sku);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[ManageSubscription.tsx]: Error caught in createAsync: ",
          err
        );
      }
      addError(err, defaultErrorConfig);
      throw err;
    }
  });
  const productData = createAsync(async () => {
    try {
      return await useProduct(props.sku, {
        fields: ["Category", "Discontinued", "SKU"],
      });
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[ManageSubscription.tsx]: Error caught in createAsync: ",
          err
        );
      }
      addError(err, defaultErrorConfig);
      throw err;
    }
  });

  // TODO Error-Boundary!
  // TODO - Change Kobalte Modal for Corvu Dialog - Kobalte does not like multiple open modals/dialogs (ie usePrompt within StockSubscribtion)

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <ErrorBoundary
        fallback={(error, reset) => {
          // TODO - improve UI? 
          return <div class="bg-red-100 px-3 py-2 rounded-md m-3">An error occurred.</div>;
        }}
      >
        <Show
          when={inventory() && productData()}
          fallback={
            <div class="flex justify-center mt-2">
              <BaseLoader width={10} height={10} class="!mr-0" />
            </div>
            // TODO - refactor this block - should use suspense boundary and error boundary rather than nested Shows
            // <Show
            //   when={inventory()?.Error?.Code === "CREDIT_BLOCK"}
            //   fallback={
            //     <div class="flex justify-center mt-2">
            //       <BaseLoader width={10} height={10} class="!mr-0" />
            //     </div>
            //   }
            // >
            //   <div class="border rounded-md p-4 m-2 text-sm bg-orange-50">
            //     Unfortunately we are unable to display details for this product
            //     because your Roma account has been blocked. Kindly contact{" "}
            //     <A href="./support" class="text-roma-blue">
            //       customer service
            //     </A>{" "}
            //     for additional support.
            //   </div>
            // </Show>
          }
        >
          <StockSubscription
            inventory={inventory()}
            product={productData()}
            activeSubscription={props.activeSubscription}
            closeModal={props.onClose}
          />
        </Show>
      </ErrorBoundary>
    </Modal>
  );
};
