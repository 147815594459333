import {
  createMemo,
  Show,
  For,
  Suspense,
  ErrorBoundary,
  type JSX,
  type Component,
} from "solid-js";
import {
  createAsync,
  useSubmission,
  useAction,
  useSearchParams,
  A,
  useNavigate,
} from "@solidjs/router";
import { Dynamic } from "solid-js/web";
import {  getSMSNumbers } from "~/services/sms";
import {
  getListSubscription,
  deleteStockSubscriptionAction,
} from "~/services/inventory-subscriptions";
import { AccountPanel } from "~/components/account";
import { SMSNumber } from "~/services/roma-api/sms/types";
import { imageUrl } from "~/utils/products";
import { facilityMapping } from "~/components/ordering/order-page";
import { ProductImage } from "~/components/product";
import { Icon } from "solid-heroicons";
import {
  checkCircle,
  xMark,
  cog_6Tooth,
  xCircle,
  arrowTopRightOnSquare,
  magnifyingGlassCircle,
  clock,
} from "solid-heroicons/outline";
import { BaseSkeleton } from "~/components/utility";
import { SubscriptionData } from "~/services/roma-api/inventory-subscriptions";
import { ManageSubscription } from "~/components/stock-subscription";
import { usePrompt } from "~/components/utility";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";
//! import { Tooltip } from "~/components/inputs-v2";

export default function StockNotifications() {
  const { addError } = useErrorContext();
  const navigate = useNavigate();

  const numbers = createAsync(async () => {
    try {
      return await getSMSNumbers();
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[stock-notifications.tsx]: Error caught in createAsync: ",
          err
        );
      }
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Subscription Error",
        message:
          "An error occurred while retrieving your subscription phone numbers. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Refresh",
            action: () => {
              window.location.reload();
            },
          },
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });
    }
  });
  const subscriptions = createAsync(async () => {
    try {
      return await getListSubscription();
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log(
          "[stock-notifications.tsx]: Error caught in createAsync: ",
          err
        );
      }
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "Subscription Error",
        message:
          "An error occurred while retrieving your subscriptions. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Refresh",
            action: () => {
              window.location.reload();
            },
          },
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });
    }
  });

  const [Prompt, openPrompt] = usePrompt();
  const [params, setParams] = useSearchParams();

  // * FUNCTIONS/ACTIONS * //

  const unsub = useAction(deleteStockSubscriptionAction);

  const unsubscribe = async (sku: string) => {
    const ok = await openPrompt({
      title: "Confirm Unsubscribe",
      description: `Are you sure you want to unsubscribe from stock notifications for ${sku}? `,
      requireInteraction: true,
      options: [
        { label: "Cancel", value: false, priority: true },
        { label: "Unsubscribe", value: true, style: "red" },
      ],
    });

    if (ok) {
      try {
        return await unsub(sku);
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            `[stock-notifications.tsx]: Error on unsubscribe submision for SKU '${sku}': `,
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Unsubscribe Error",
          message:
            "An error occurred while unsubscribing. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    }
  };

  type ActionButtonProps = {
    icon: { path: JSX.Element };
    label?: string;
  } & (
    | {
        type?: "button";
        onClick: () => void;
      }
    | {
        type: "link";
        href: string;
        target?: string;
      }
  );

  const numbersDictionary = createMemo(() => {
    if (!numbers() || !numbers()?.Results) return {};

    return numbers()?.Results.reduce((memo: Record<string, string>, item) => {
      memo[item.ID] = item.PhoneNumber;
      return memo;
    }, {} as Record<string, string>);
  });

  // *  COMPONENTS  * //
  const ActionButton: Component<ActionButtonProps> = (props) => {
    return (
      <Dynamic
        component={props.type === "link" ? "a" : "button"}
        onClick={props.type !== "link" ? props.onClick : undefined}
        href={props.type === "link" ? props.href : undefined}
        target={props.type === "link" ? props.target : undefined}
        title={props.label ?? undefined}
        class="border border-gray-400 text-gray-400 rounded-md w-8 aspect-square flex justify-center items-center hover:bg-gray-100 hover:text-gray-700 transition"
      >
        <Icon path={props.icon} class="w-5 h-5" />
      </Dynamic>
    );
  };

  const SubscriptionRow: Component<{
    subscription: SubscriptionData;
  }> = (props) => {
    return (
      <tr class=" w-full border-b ">
        <td class="flex flex-col pt-3 pb-1 w-[100px] items-center">
          <A href={`/product/${props.subscription.SKU}`}>
            <div class="bg-roma-grey  w-16 p-2 flex items-between justify-center border-gray-200 hover:border-gray-300 border rounded-[4px]">
              <ProductImage
                src={imageUrl(props.subscription.SKU, "moulding", 1)}
                width={80}
                height={80}
                quality={90}
                alt=""
              />
            </div>
          </A>
          <p class="text-sm mt-1 text-roma-dark-grey text-center">
            {props.subscription.SKU}
          </p>
        </td>
        <td class="">
          <div class="flex justify-center gap-2 text-xs flex-wrap">
            <For each={props.subscription.PlantsWaiting}>
              {(item) => (
                // <Tooltip content="Awaiting stock">
                <div class="bg-gray-200 pl-1 pr-3 py-1 rounded-full flex items-center">
                  <span>
                    <Icon path={clock} class="w-4 h-4 mr-1" />
                  </span>
                  <span>{facilityMapping[item]}</span>
                </div>
                // </Tooltip>
              )}
            </For>
            <For each={props.subscription.PlantsNotified}>
              {(item) => (
                // <Tooltip content="Re-stocked and notified">
                <div class="border border-gray-200 pl-1 pr-3 py-1 rounded-full flex items-center text-gray-400">
                  <span>
                    <Icon path={checkCircle} class="w-4 h-4 mr-1" />
                  </span>
                  <span>{facilityMapping[item]}</span>
                </div>
                // </Tooltip>
              )}
            </For>
          </div>
        </td>
        <td>
          <div class="flex justify-center">
            <Icon
              path={props.subscription.Email ? checkCircle : xCircle}
              class={`w-8 h-8 ${
                props.subscription.Email
                  ? "text-success-green"
                  : "text-gray-400"
              }`}
            />
          </div>
        </td>
        <td>
          <div class="flex flex-wrap justify-center gap-2 text-xs">
            <Show
              when={props.subscription?.SMS?.length >= 1}
              fallback={<Icon path={xCircle} class="w-8 h-8 text-gray-400" />}
            >
              <For each={props.subscription.SMS}>
                {(item) => (
                  <div class="bg-gray-200 px-3 py-1 rounded-full">
                    {/* {numbers()?.IDToNum?.[item]} */}
                    {numbersDictionary()?.[item]}
                  </div>
                )}
              </For>
            </Show>
          </div>
        </td>
        <td>
          <div class="flex justify-center gap-1">
            {/* <Tooltip content="Unsubscribe"> */}
            <ActionButton
              onClick={() => unsubscribe(props.subscription.SKU)}
              icon={xMark}
            />
            {/* </Tooltip> */}
            {/* <Tooltip content="Manage subscription"> */}
            <ActionButton
              onClick={() => setParams({ manage: props.subscription.SKU })}
              icon={cog_6Tooth}
            />
            {/* </Tooltip> */}
            {/* <Tooltip content="View product (new tab)"> */}
            <ActionButton
              type="link"
              icon={arrowTopRightOnSquare}
              href={`/product/${props.subscription.SKU}`}
              target="_blank"
            />
            {/* </Tooltip> */}
          </div>
        </td>
        <Show when={params.manage === props.subscription.SKU}>
          <ManageSubscription
            open={() => params.manage === props.subscription.SKU}
            onClose={() => setParams({ manage: undefined })}
            activeSubscription={props.subscription}
            sku={props.subscription.SKU}
          />
        </Show>
      </tr>
    );
  };

  return (
    <AccountPanel>
      {/* TODO - Error-Boundary */}
      {/* <ErrorBoundary fallback={(err) => <div>ERROR: {err}</div>}> */}
      {/* <Suspense fallback="TOP LEVEL FALLBACK"> */}
      <div class="max-w-2xl pb-4 ">
        <h2 class="text-3xl font-bold  pb-2">Stock Notifications</h2>
        <p>
          Discover our real-time stock notification service! Easily view and
          manage all your active SMS/Email notifications. It’s simple,
          informative, and allows you to save valuable time for what matters
          most. <br />
          Kindly note, all notifications are estimates based on third-party
          freight carriers data
        </p>
      </div>

      <Suspense fallback={<BaseSkeleton height={400} />}>
        <table class=" w-full border-collapse mt-8">
          <thead>
            <tr class="bg-roma-grey text-xs text-roma-dark-grey font-normal">
              <th class="w-[100px] py-2">Moulding</th>
              <th class="py-2">Distribution Center</th>
              <th class="py-2">Email</th>
              <th class="py-2">SMS</th>
              <th class="py-2 w-[120px]">Action</th>
            </tr>
          </thead>

          <Show
            when={
              subscriptions()?.Results && subscriptions()?.Results?.length! >= 1
            }
            fallback={
              <tr>
                <td colspan="100%">
                  <div class="flex justify-center items-center">
                    <div class=" px-8 py-10 my-10 mx-3 rounded-lg text-roma-dark-grey/50 flex flex-col gap-4 justify-center items-center">
                      <Icon path={magnifyingGlassCircle} class="w-12 h-12" />
                      <p>
                        You have no currently active stock notification
                        subscriptions.
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            }
          >
            <For each={subscriptions()?.Results}>
              {(sub) => <SubscriptionRow subscription={sub} />}
            </For>
          </Show>
        </table>
      </Suspense>
      {Prompt}
    </AccountPanel>
  );
}
